import React from 'react';  
import { Link } from 'react-router-dom';   
import { useEffect, useState } from 'react'; 
import { getPrincipalMessage } from '../Service/Api'; 
import Slider from "react-slick";
const PrincipalMessage = () => { 
    var settings = {
        autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    };
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
        { id: 1, category: "Principal", message: "Honourable A.P.J Abdul Kalam said “Real Education enhance  the dignity of human being and increases his or her self respect. If only the real sense of education could be realised by each individual and carried forward in every field of human activity, the world would be so much a better place to live in." , name: "Name", images: "/Images/principal.jpg" }
     ];
    const emptyArrayData = [
        { id:1, category: "Director", message: "Education is the courage and strength to walk on the path of truth, humility and hard work.  Education is the soul of the society as it passes from one generation to the other." , name: "Name", images: "/Images/Director.jpg" }
    ];
    return (

        <Slider {...settings}>
        {data?.length > 0 ? data?.filter((div) => div.category === "Principal").map((item, index) => ( 
            <div className="item" key={index}>
            <div className="message-block">
                <div className='message-image animateMe' data-animation="fadeInRight">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} />

                </div> 
                <div className='message-content animateMe' data-animation="fadeInLeft">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/message.gif" className="quotes" />
                    <div className='princi-Name'>

                        <h3>{item.name}</h3>
                        <p>{item.category}</p>
                    </div>
                    <div className="homemsgdesc">
                        <div dangerouslySetInnerHTML={{ __html: item.message }} /> 
                    </div>
 
                <Link to="/PrincipalMessage">
                 <div class="button light padding">&nbsp;<span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></div> 
                </Link>
            </div>
            </div>
           </div>  
           

           )) : emptyData.map((data, item) => ( 
                <div className="item" key={item.id} >
                <div className="message-block">
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Principal.png" />

                    </div> 
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/message.gif" className="quotes" />
                        <div className='princi-Name'>

                            <h3>{data.name}</h3>
                            <p>{data.category}</p>
                        </div>
                        <div className="homemsgdesc">  <p>{data.message}</p> </div>
                    <Link to="/PrincipalMessage">
                     <div class="button light padding">&nbsp;<span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></div> 
                    </Link>
                </div>
                </div>
               </div> 
    
               ))}
           
               {data?.length > 0 ? data?.filter((div) => div.category === "Director").map((item, index) => ( 
                <div className="item" key={index}>
                <div className="message-block">
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} />
    
                    </div> 
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/message.gif" className="quotes" />
                        <div className='princi-Name'>
    
                            <h3>{item.name}</h3>
                            <p>{item.category}</p>
                        </div>
                        <div className="homemsgdesc">
                         <div dangerouslySetInnerHTML={{ __html: item.message }} /> 
                     </div>
  
     
                    <Link to="/DirectorMessage">
                     <div class="button light padding">&nbsp;<span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></div> 
                    </Link>
                </div>
                </div>
               </div>  
               
               )) : emptyArrayData.map((data,item) => ( 
                <div className="item" key={item.id}>
                    <div className="message-block">
                        <div className='message-image animateMe' data-animation="fadeInRight">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/director.jpg" />
    
                        </div> 
                        <div className='message-content animateMe' data-animation="fadeInLeft">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/message.gif" className="quotes" />
                            <div className='princi-Name'>
    
                                <h3>{data.name}</h3>
                                <p>{data.category}</p>
                            </div>
                            <div className="homemsgdesc">  <p>{data.message}</p> </div>
                        <Link to="/DirectorMessage">
                         <div class="button light padding">&nbsp;<span>view More</span> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></div> 
                        </Link>
                    </div>
                    </div>
                   </div> 
        
                   ))}
        </Slider>

    );
};

export default PrincipalMessage;
