import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/home';
import KinderGarten from './Junior/KinderGarten'
import Vision from './Pages/vision';
import About from './Pages/about'
import Mission from './Pages/mission';
import ContactUs from './Pages/contactUs';
import SMC from './Pages/smc';
import Gallery from './Pages/Gallery';
import Events from './Pages/Events';
import News from './Pages/News';
import VideoGallery from './Pages/VideoGallery';
import SubGallery from './Pages/SubGallery';
import PrincipalMessage from './Pages/PrincipalMessage'
import DirectorMessage from './Pages/DirectorMessage'
import Cbse from './Pages/Cbse';
import Infrastructure from './Pages/Infrastructure'
import Tc from './Pages/Tc'
import Celebrations from './Pages/Celebrations'
import Result from './Pages/Result'
import AnnualReport from './Pages/AnnualReport'
import Assemblies from './Pages/Assemblies'
import Competition from './Pages/Competition'
import Activites from './Pages/Activites'
import Circulars from './Pages/Circulars'
import Pphelp from './Pages/Pphelp'
import Sports from './Pages/Sports'
import ErpSoftware from './Pages/ErpSoftware'
import Achivement from './Pages/Achivement'
import RulesRegulations from './Pages/RulesRegulations'
import Career from'./Pages/Career' 
import JuniorActivites from './Junior/JuniorActivites'
import JuniorAssemblies from './Junior/JuniorAssemblies'
import JuniorCelebrations from './Junior/JuniorCelebrations'
import JuniorClassroom from './Junior/JuniorClassroom'
import JuniorSports from './Junior/JuniorSports'
import JuniorCompetition from './Junior/JuniorCompetition'
import JuniorSubGallery from './Junior/JuniorSubGallery' 
function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/KinderGarten" element={<KinderGarten />} />
          <Route path="/About" element={<About />} />
          <Route path="/Vision" element={<Vision />} />
          <Route path="/Mission" element={<Mission />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/smc" element={<SMC />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/News" element={<News />} />
          <Route path="/VideoGallery" element={<VideoGallery />} />
          <Route path="/SubGallery" element={<SubGallery />} />
          <Route path="/PrincipalMessage" element={<PrincipalMessage />} />
          <Route path="/DirectorMessage" element={<DirectorMessage />} />
          <Route path="/Tc" element={<Tc />} />
          <Route path="/Cbse" element={<Cbse />} />
          <Route path="/Infrastructure" element={<Infrastructure />} />
          <Route path="/Celebrations" element={<Celebrations />} />
          <Route path="/Result" element={<Result />} />
          <Route path="/AnnualReport" element={<AnnualReport />} />
          <Route path="/Assemblies" element={<Assemblies />} />
          <Route path="/Competition" element={<Competition />} />
          <Route path="/Activites" element={<Activites />} />
          <Route path="/Circulars" element={<Circulars />} />
          <Route path="/Pphelp" element={<Pphelp />} />
          <Route path="/ErpSoftware" element={<ErpSoftware />} />
          <Route path="/Sports" element={<Sports />} />
          <Route path="/Achivement" element={<Achivement />} /> 
           <Route path="/Career" element={<Career />} /> 
          <Route path="/JuniorActivites" element={<JuniorActivites />} />
          <Route path="/JuniorAssemblies" element={<JuniorAssemblies />} />
          <Route path="/JuniorCelebrations" element={<JuniorCelebrations />} />
          <Route path="/JuniorClassroom" element={<JuniorClassroom />} />
          <Route path="/JuniorSports" element={<JuniorSports />} />
          <Route path="/JuniorCompetition" element={<JuniorCompetition />} />
          <Route path="/JuniorSubGallery" element={<JuniorSubGallery />} />
          <Route path="/RulesRegulations" element={<RulesRegulations />} /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;