import React, { useState, useEffect } from 'react'; 
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb"; 
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => { 
    useEffect(() => {
        document.title = 'Directo rMessage - Tagore Public School, Palwal ';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
     <Breadcrumb mid="About" breadcrumbName="Principal  Message" />
         <div className="innerSection">
             <div className="container">
                 <div className="row">
                     <div className="col-lg-12">
                     <div className="title">
                             <div>
                                 <h3><span>Principal</span> Message</h3>
                             </div>
                         </div>
                         </div>
                      </div>
                <div className="row">
                    <div className="col-lg-12">
                    
                        {data?.length > 0 ? data?.filter((div) => div.category === "Principal").map((item) => ( 
                        <div className="clearfix">
                         <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="" className="col-md-4 float-md-end  left-img img-fluid"/>
                        
                         <p> <div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                         <div className="mesdeskimg"> 
                         <h3> {item.name}</h3>
                         <h5> {item.category}</h5>
                      </div>
                         </div>
                     )) : emptyData.map((item) => (
                         <div className="clearfix"> 
                         <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Principal.png" className="col-md-4 float-md-end  left-img img-fluid"/>
                         <p>Honourable A.P.J Abdul Kalam said “Real Education enhance  the dignity of human being and increases his or her self respect. If only the real sense of education could be realised by each individual and carried forward in every field of human activity, the world would be so much a better place to live in.</p>

                         <p>Shaping young impressionable minds is one of the life’s biggest challenges. At Tagore we aim to empower our students to grow as an individual with strong decision making capacity and an open mind with an international perspective. The soul of education is to prepare the students to make a mark and excel in the professional field whatever they choose. </p>
                         <p>We are passionately committed to contribute to the field of education and pursuing best practice strategies for student’s learning. It is always our concerted goal to provide the highest level of educational services to the families that have entrusted their children to us. On one hand our commitment extends to individual needs of every child and on the other, to stay at the top of the educational field with research and updating our content regularly.</p>
                       
                         <p>Teaching is a noble profession that shapes the character , calibre and future of an individual. It is the collaborative efforts of school teachers and parents to bring out the best out of their children. Most importantly our school has safe and nurturing environment where students learn and grow under qualified , caring teachers guidance. It is always our earnest effort to keep the highest standard in what ever we do and always strive to beat our own record.</p>
                       
                         <p>Our school staff continues to work hard to devise and implement strategies and lessons that compliment ever changing educational pedagogy so that out students are better prepared for next class. Academic activity planner is designed to ignite the creative interest and expression of the students and to identify their hidden talents.</p>
                       
                         <p>As rightly said by Martn Luther King Jr. “The function of Education is to teach one to think intensively and to think critically. Intelligence plus character – <b>That is the goal of true education”.</b></p>
                           <div className="mesdeskimg"> <h3> Name</h3> <h5>  Principal  </h5></div>
                         </div> 
                         ))}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Copyright />
     </>
  )
}

export default PrincipalMessage

