import React, { useState, useEffect } from 'react'; 
import Header from '../components/Header';
import Copyright from '../components/Copyright'; 
import Footer from '../components/Footer';
import { getGalleryInner } from '../Service/Api';
import { Link } from 'react-router-dom';

const KinderGarten = () => { 
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getGalleryInner();
                setData(galleryData);
            } catch (error) {
                setError("Error fetching gallery data.");
                console.error("Error fetching gallery data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const categories = [
        { name: "Activities", path: "/JuniorActivites", defaultImage: "Activites4.jpg" },
        { name: "Competitions", path: "/JuniorCompetition", defaultImage: "Competition_8.jpg" },
        { name: "Assemblies", path: "/JuniorAssemblies", defaultImage: "Assembly6.jpg" }, 
        { name: "Celebration", path: "/JuniorCelebrations", defaultImage: "Competition9.jpg" },
        { name: "Sports", path: "/JuniorSports", defaultImage: "Sports13.jpg" },
    ];

    const renderGallery = (category) => {
        const filteredData = data.filter(item => item.school === "Junior" && item.category === category.name);
        return (
            <>
                {filteredData.length > 0 ? filteredData.map((item) => (
                    <div className="gal-img" key={item.id}>
                        <img 
                            src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} 
                            className="img-fluid" 
                            alt={item.title} // Added alt attribute
                        />
                        <div className="description">                              
                            <Link to={category.path}>
                                <h3>{item.title}</h3>
                                <span>
                                    <img 
                                        src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" 
                                        alt="Arrow icon" // Added alt attribute
                                    />
                                </span>
                            </Link>
                        </div>
                    </div>
                )) : (
                    <div className="gal-img">
                        <img 
                            src={`https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/${category.defaultImage}`} 
                            className="img-fluid" 
                            alt={category.name} // Added alt attribute
                        />
                        <div className="description">                              
                            <Link to={category.path}>
                                <h3>{category.name}</h3>
                                <span>
                                    <img 
                                        src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" 
                                        alt="Arrow icon" // Added alt attribute
                                    />
                                </span>
                            </Link>
                        </div>
                    </div>
                )}
            </>
        );
    };
 

    return (
        <> 
            <Header /> 
            <div className="kindergal">
                <div className="container">
                    <div className="row gallery-box">  
                        {categories.map((category) => (
                            <div className="col-lg-4 col-md-4 col-sm-6" key={category.name}>
                                {renderGallery(category)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />  
            <Copyright />
        </>
    );
}

export default KinderGarten;
