import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
const ErpSoftware = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="Erp Software" breadcrumbName="Erp Software" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Erp </span> Software</h3>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            <div className="row">
                            <div className="col-12 col-sm-12 col-md-9">
                            <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                            <p className="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and <Link to="https://www.entab.in/mobile-apps.html"> <b> mobile apps</b></Link>.<br/>
                            <br/>
                            The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with  <Link to="https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front.</p>
                             </div>
                            </div>
                            <div className="clr25"></div>
                            <div className="row"> 
                                 
                                 <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                      <h4 className="imgheading"><Link to="https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                                      <img id="myImg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
                                      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div className="modal-dialog">
                                      <div className="modal-content">
                                          <div className="modal-header">
                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div className="modal-body">
                                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="img-fluid"/>
                                          <h5>School Management System, Management ERP Software</h5>   
                                      </div>
                                      </div>
                                  </div>
                                  </div>
                                      
                                 </div>
                                 <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                      <h4 className="imgheading"><Link to="https://www.entab.in/school-communication-portals.html#PPC"> Parent Portal</Link></h4>
                                      <img id="myImg1" src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"/>
                                      <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div className="modal-dialog">
                                      <div className="modal-content">
                                          <div className="modal-header">
                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div className="modal-body">
                                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" className="img-fluid"/>
                                          <h5>Entab Parent Portal, School Software</h5>
                                      </div>
                                      </div>
                                  </div>
                                  </div>
                                 </div>
                                 <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                      <h4 className="imgheading"><Link to="https://www.entab.in/school-communication-portals.html#PPC"> Communication Portal</Link></h4>
                                      <img id="myImg2" src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/communication.gif" alt="School Communication Portal" className="animation  center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"/>
                                      <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div className="modal-dialog">
                                      <div className="modal-content">
                                          <div className="modal-header">
                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div className="modal-body">
                                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/communication.gif" alt="School Communication Portal" className="img-fluid"/>
                                          <h5>School Communication Portal</h5>
                                      </div>
                                      </div>
                                  </div>
                                  </div>                         
                                 </div>
                            </div>
                       </div>
                       <div className="col-12 col-sm-12 col-md-3">
                            <h4 className="imgheading"><Link to="https://www.entab.in/mobile-apps.html">CampusCare</Link></h4>
                           <center><Link to="https://www.entab.in/mobile-apps.html"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Mobile-slides.gif" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School"/></Link> </center>
                           </div>
                       
                             </div>
           
                         </div>
                    </div> 
                 <Footer />
                 <Copyright />
     </>
  )
}

export default ErpSoftware

