 import React, { useRef ,useState} from 'react';
import Header from '../components/Header'
import News from '../components/News'
import Copyright from '../components/Copyright'
import SliderComponent from '../components/lightslider'
import Events from '../components/Events'
import PrincipalMessage from '../components/PrincipalMessage'  
import Footer from '../components/Footer'
import { Link } from 'react-router-dom' 
import Highlights from '../components/Highlights'
import Facilities from '../components/Facilities'; 
import Gallery from '../components/Gallery'
import Circulars from'../components/Circulars'
import Notification from'../components/Notification'
import OnlineReg from'../components/OnlineReg'
import VisitCounter from'../components/VisitCounter' 
const Home = () => {

    const videoRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
  
    const handlePlay = () => {
      setIsOpen(true); // Open the popup
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.play(); // Play the video after opening
        }
      }, 100); // Slight delay to ensure the video is ready to play
    };
  
    const handlePause = () => {
      if (videoRef.current) {
        videoRef.current.pause(); // Pause the video
      }
    };
  
    const handleClose = () => {
      setIsOpen(false); // Close the popup
      handlePause(); // Pause the video when closing the popup
    };
   
    return (
        <> 
    <Notification/> 
    <OnlineReg/>
           <Header/>

            {/* <div className='online-reg'>
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/registration.png" />
                </div> */}
            <div className='container-fluid min-ht'>
                <div className="row">

                    <div className='col-md-12 pd-0 order-lg-12'>
                        <SliderComponent />
                        <div className='contact-header'>
                           <p> <a href="tel: +91-8930729377"><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/phone.gif' /> +91  8930729377 ,</a> <a href="tel: +91-7027770212"> +91  7027770212</a> </p>
                               
                            <a href="mailto: tagore_academy@rediffmail.com"><img src='/Images/email.gif' /> tagorepalwal@gmail.com</a>
                        </div>
                        <div className='social-media'>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/facebook.png" />
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/youtube.png" />
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/linkdin.png" />
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/twitter.png" />
                        </div>
                        <div className='highlihgt animateMe' data-animation="fadeInTop" >
                <div className='icon'>
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/announcement.gif"></img>
                </div>
                <Highlights />
            </div>
                    </div>
                </div>

            </div>
            
            <div className="message">
                <div>
                    <div className='container-fluid'>
                        <div class="row">
                         <div className='col-lg-8 col-md-12 animateMe' data-animation="fadeInLeft">
                               <div class="abouttit">
                               <div className='title'>
                               <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/email.png" /> from the desk of</p>
                                <h3><span>Messege</span> section</h3>
                             </div>
                               </div> 
                                    <PrincipalMessage /> 
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm order-sm-1 animateMe" data-animation="fadeInRight">
                            <div className='about-virtual'>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/videobg.jpg" className="img-fluid videobg" alt="Video Background" />
                            <div className='text'>
                              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/vt.png" alt="Virtual Tour Icon" />
                              <h3>Virtual Tour made it <span>Simple</span></h3>
                              <button onClick={handlePlay}>View Virtual Tour</button>
                            </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>

            <div class="bg-image">

                <div className="about-us">
                    <div>
                     <div className='container-fluid'>
                        <div className='row'>
                           
                            <div className='col-lg-5 col-md-12 animateMe' data-animation="fadeInRight">
                                 <img src="Images/about.png" className='about-logo img-fluid' />
                                   <div className="title">
                                    <div>
                                       <span>PALWAL</span>
                                        <h3><span>ABOUT </span> TAGORE PUBLIC SCHOOL</h3>
                                    </div>
                                </div>
                                <p>  Tagore Public School was founded in the year 2012. Situated in the heart of sector-2 Palwal, the school has beautiful infrastructure with lush green lawns. The school is progressing leaps and bounds as the students strength is touching 3000 under the dynamic and progressive leadership of Academic Director Madam Ms. Manorma Arora. She is the proud recipient of Rajat Jyanti and State Award for her valuable and outstanding contribution in the field of education, Art and Culture. The school is a promising educational centre reflecting Madam Manorma’s vision to produce IITians, IAS, Doctors, Engineers, CA’s, and other successful professionals in diverse fields. </p>
                                  {/* <div className='button abt-button'><Link to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Read-more.png" /></Link></div> */}
                                 <Link to="/About"><div className="button"><span>View More</span> <div className='img'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"></img></div></div></Link>
                               
                                 </div>
                             <div className='col-lg-7 col-md-12 animateMe aboutimg' data-animation="fadeInLeft">
                                <div className='about-virtual'>
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/aboutus.jpg" className='img-fluid' />
                                </div>
                               
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="imp-link">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3 col-sm-6 animateMe'  data-animation="fadeInLeft">
                        <div className='d-flex'>
                        <Link to="/Cbse">
                           <div className="imp-img">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/CBSE.gif" />
                            </div>
                            <h4>CBSE INFO</h4>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 animateMe'  data-animation="fadeInUp">
                        <div className='d-flex'>
                        <Link to="/Tc">
                            <div className="imp-img">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/doc.gif" />
                            </div>
                             <h4>TC</h4>
                             </Link>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 animateMe' data-animation="fadeInRight">
                        <div className='d-flex'>
                            <Link to="Result">
                            <div className="imp-img">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/result.gif" />
                            </div>
                            <h4> RESULTS</h4>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-6 animateMe' data-animation="fadeInRight">
                    <div className='d-flex'>
                         <Link to="AnnualReport">
                                <div className="imp-img">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/newsletter.gif" />
                            </div>
                            <h4>  ANNUAL REPORT</h4>
                         </Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
            <div className='facilities'>
                  <div class="row">
                       <div class="col-lg-6">
                       <div>
                            <div className="title">
                                <div>
                                    <p>TAGORE PUBLIC SCHOOL</p>
                                    <h3><span>OUR</span> FACILITIES</h3>
                                </div>
                            </div>
                            <Facilities />
                        </div>
                       </div>
                       <div class="col-lg-6 facilities-events">
                       
                       <div className="title center">
                           <div>
                               <p>UPSHORT OF ACTIVITIES</p>
                               <h3><span>CURRENT</span> EVENTS</h3>
                           </div>
                           <div class="button padding arrow"> <div class="img mar-0"> <Link to="/Events"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></Link></div></div>
                       </div>
                       <div className='events-blk'>
                           <Events />
                       </div>
                    
                       </div>
                  </div>
            </div>
         
            <div className='news-event'>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-4 news-bg animateMe' data-animation="fadeInLeft">

                                <div className='news newsbg'>
                                    <div className="title">
                                        <div class="leftimg">
                                           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/newspaper.png" className='anim-icon' />
                                        </div>
                                        <div>
                                            <p>whats happening</p>
                                            <h3><span>latest </span>  NEWS  </h3>
                                        </div>
                                        <div class="button padding arrow"> <div class="img mar-0"> <Link to="/News"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></Link></div></div>
                                    </div>
                                    <div className='news-blk'>
                                        <News />
              
                                    </div>
                                </div>


                                
                                <div className='news achievbg'>
                                    <div className="title">
                                        <div class="leftimg">
                                           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/achiev.png" className='anim-icon' />
                                        </div>
                                        <div>
                                            <p>TARGETS ACHIEVED</p>
                                            <h3><span>latest </span>  Circulars</h3>
                                        </div>
                                        <div class="button padding arrow"> <div class="img mar-0"><Link to="/Circulars"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></Link></div></div>
                                    </div>
                                    <div className='news-blk'>
                                        <Circulars />
              
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 animateMe' data-animation="fadeInRight">

                                <div className='events'>
                                            <div class="title center">
                                            <div>
                                                <div class="galsec">
                                                     <div class="galsecimg">
                                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/gallery-icon.png" className='anim-icon img-fluid' />
                                                    </div>
                                                    <div class="galsectit">
                                                        <p>SCHOOL IN THE MEDIA</p>
                                                        <h3><span>SCHOOL</span>  GALLERY</h3>
                                                     </div>
                                                </div>
                                            </div>
                                           {/* <div class="button padding arrow"> <div class="img mar-0"> <Link to="/Gallery"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png" /></Link></div></div>  */}
                                        </div>
                                        <Gallery />
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            {/* <div className='facilities-main'>
            <div className="facilities">
                <div className="title center">
                    <div>
                        <p>facilities in the school</p>
                        <h3>Our Facilities</h3>
                    </div> 
                </div>
                <Facilities />
            </div>
            </div> */} 
            
           <div className="footervisit">
               <VisitCounter/>
                <Footer /> 
           </div>
            <Copyright />
            {isOpen && (
                <div className="video-popup">
                <div className="video-popup-content">
                    <button className="close-button" onClick={handleClose}>×</button>
                      {/*  <video
                      width="100%"
                      height="auto"
                      ref={videoRef}
                      controls={false}
                      loop
                      poster="/Images/video.png"
                    >
                      <source src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/virtual.mp4" type="video/mp4" />
                    </video> */}
                    <iframe 
                    width="100%" 
                    height="100%" 
                    src="https://www.youtube.com/embed/20n_0WlkvFM?si=-Pu3ATfpk9NpjNAX&autoplay=1" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                </iframe>
                    </div>
                </div>
              )}
        </>
    )
}
export default Home;