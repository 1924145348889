import React, { useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";  
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
const Career = () => {
    const [successMessage, setSuccessMessage] = useState("");
    const [formData, setFormData] = useState({
        name: "", 
        phone: "",
        subject: "",
        mailType: "",
        experience: "",
        attachments: null,  
        
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        if (e.target.type === "file") {
            setFormData({
                ...formData,
                attachments: e.target.files[0], // Save the file object
            });
            setErrors({ ...errors, attachments: "" }); // Clear any previous error for file
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
            setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.subject) newErrors.subject = "Teaching Subject is required.";
        if (!formData.phone) newErrors.phone = "Contact Number is required.";
        if (!formData.mailType) newErrors.mailType = "Post Applied  is required.";
        if (!formData.experience) newErrors.experience = "Total Exp is required.";
        if (!formData.attachments) newErrors.attachments = "Please upload CV (  PDF).";
        
        if (formData.attachments) {
            const validTypes = ['application/pdf'];
            if (!validTypes.includes(formData.attachments.type)) {
                newErrors.attachments = "Invalid file type. Only PDF are allowed.";
            }
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'ABC'; 
        console.log('Submitting form with type:', type); // Debugging line

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        try {
            const { header } = getHeader(); // Make sure this function returns the correct headers
            const response = await form1(formDataToSend, header, type); 
            console.log('Form submitted successfully', response);
            setSuccessMessage("Your Form Submitted Successfully.");
            setFormData({
                name: "", 
                phone: "",
                subject: "",
                mailType: "",
                experience: "",
                attachments: null,
                
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    return(
        <>
            <Header />
            <Breadcrumb mid="Careers"/>
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Careers</span></h3>
                                    </div>
                                </div>
                                </div>
                            <div className="col-lg-12">
                            <form className='careerform-feilds' onSubmit={handleSubmit}>
                            <div>
                                <label> Name</label>
                                <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div> 
                            <div>
                                <label>Contact  Number</label>
                                <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone" className="form-control" maxlength={10} value={formData.phone} onChange={handleChange}/>
                                {errors.phone && <div className="error">{errors.phone}</div>}
                            </div>   
                            <div>
                            <label>Post Applied For</label>
                            <input type="text"  name="mailType" className="form-control" value={formData.mailType} onChange={handleChange} />
                            {errors.mailType && <div className="error">{errors.mailType}</div>}
                        </div>
                            <div>
                                <label>Teaching Subject</label>
                                <input type="text" name="subject" className="form-control" value={formData.subject} onChange={handleChange}/>
                                {errors.subject && <div className="error">{errors.subject}</div>}
                            </div>
                        <div>
                            <label>Total Exp.</label>
                            <input type="text"  name="experience"className="form-control"  value={formData.experience} onChange={handleChange} />
                            {errors.experience && <div className="error">{errors.experience}</div>}
                        </div> 
                        <div>
                        <label>Upload CV  (Only in Pdf Format):</label>
                        <input className="form-control"
                            type="file"
                            name="attachments"
                            accept=".pdf"
                            onChange={handleChange}
                        />
                        {errors.attachments && <div className="error">{errors.attachments}</div>}
                    </div>
                        
                    <div className="submit">
                    <input type="submit" value="Submit" /> 
                </div>
                <div className="row"> 
                    <div className="col-lg-12 col-md-12">
                    {successMessage && <div className='primary-alert'>{successMessage}</div>} 
                    </div>
                </div>
                        </form>
                             </div> 
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Career;