import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/TPSP`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/TPSP`);
    console.log("News" ,data)
    return data;
} 


export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/TPSP`);
    console.log(data);
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/TPSP`);
    console.log(data);
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/TPSP`);
    console.log("innerEvents" ,data)
    return data;
}
 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/All/TPSP`);
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/TPSP`);
    console.log("gallery",data);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/TPSP`);
    console.log("galleryinner" , data);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data);
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/TPSP`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/TPSP`,getHeader());
    return data;
}  

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/TPSP`, getHeader());
    console.log("message" , data);
    return data;
} 

  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/TPSP`,{name,admission},getHeader());
    return data;
}
  
export const postVisitor = async() => {
  const { data } = await axios.post(API_URL + `/api/visitor`, { schoolcode: "TPSP" }, getHeader());
  console.log(data)
  return data;
}
export const getVisitor= async() => {
  const { data } = await axios.get(API_URL + `/api/visitor/TPSP`, getHeader());
  console.log("service code", data)
  return data;
}
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/TPSP`, getHeader());
    console.log("circular", data);
    return data;
} 
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/TPSP/${type}`, formData, header);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };