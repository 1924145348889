// export const API_URL = "http://3.81.146.113" 
export const API_URL = "https://webapi.entab.info"; 

export const getHeader = () => {

    const token = localStorage.getItem('token');
    const schoolcode = localStorage.getItem('schoolcode');

    return {
        header: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ZTAzMDhiOWI2NWY0OGRkZGE5MTM2OCIsImlhdCI6MTcxMDc2MzcyNiwiZXhwIjoxNzEwNzY3MzI2fQ.l0PcVg6V-wKa1UqQ5ZN2caNcAwVlWQuH6GO9kS74X8I`
        },
        schoolcode,
    }
}
    
