import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Mission = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Mission Statement" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Mission </span> Statement</h3>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="about-img">
                                
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/mission.jpg" className="img-fluid"/> 
                                </div>
                            </div>
                            <div className="col-lg-6">
                              <p>Our mission is to ensure best quality and holistic education through optimum use of human and infrastructural resources. With time tested cultural and social value system in place and dedicated efforts are made to inspire and empower our students to make them leaders in true sense.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Mission;