import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink,useLocation } from 'react-router-dom';  
import AdmissionEnquiry from'./AdmissionEnquiry'
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
const Header = () => {
    const { pathname } = useLocation(); 
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]); 
    const [isOpen, setIsOpen] = useState(false); 
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }; 
    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 991);
        }; 
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 
    const [active, setActive] = useState(""); 
    const [visible,setVisible] = useState(false);
    const handlePopupEnquiry = () =>{
        setVisible("visible")
    }

    const handlePopuClose = () =>{
        setVisible("");
    }
    const handlePopup = () => { 
        setActive("active");
    } 
    const handleClose = () => { 
        setActive("");
    }
  
    
    const [formData, setFormData] = useState({
        name: "", 
        phone: "",
        fatherName: "",
        school: "",
        phone1: "",
        attachments: null,  
        class: "",  
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        if (e.target.type === "file") {
            setFormData({
                ...formData,
                attachments: e.target.files[0], // Save the file object
            });
            setErrors({ ...errors, attachments: "" }); // Clear any previous error for file
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
            setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.fatherName) newErrors.fatherName = "Father's Name is required.";
        if (!formData.phone) newErrors.phone = "Phone Number is required.";
        if (!formData.school) newErrors.school = "School Name is required.";
        if (!formData.phone1) newErrors.phone1 = "Phone Number is required.";
        if (!formData.attachments) newErrors.attachments = "Please upload a pay slip (JPEG or PDF).";
        if (!formData.class) newErrors.class = "Please select a class.";
        if (formData.attachments) {
            const validTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
            if (!validTypes.includes(formData.attachments.type)) {
                newErrors.attachments = "Invalid file type. Only JPEG and PDF are allowed.";
            }
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'Admission'; 
        console.log('Submitting form with type:', type); // Debugging line

        // Create a FormData object
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        try {
            const { header } = getHeader(); // Make sure this function returns the correct headers
            const response = await form1(formDataToSend, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            setFormData({
                name: "", 
                phone: "",
                fatherName: "",
                school: "",
                phone1: "",
                attachments: null,
                class: "",
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    return (

        <div className="header "> 
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xxl-3 col-xl-3 col-lg-12'>
                            <Link to="/">
                                <div className='logo'>
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Logo.png" className='img-fluid'></img>
                                </div>
                            </Link>
                        </div>
                        <div className='col-xxl-9 col-xl-9 col-lg-12'>
                            <div className="contact-tab">
                                <div><div className="button" onClick={handlePopupEnquiry}><span>Admission Info </span><div className='img'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/icon1.png"></img></div></div></div>
                                <div><div className="button menubtn" onClick={toggleMenu}><span>  Menu</span><div className='img'> <img src="/Images/menu.png" /></div> </div>
                                   
                                </div>
                                <div><div className="button light padding"> <Link to="/Career"><span>Careers </span></Link></div></div>
                                <div className="online-reg" onClick={handlePopup}><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/online-reg.png" /></div>
                               <div className="kinderbtn"><Link to="/KinderGarten"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/kinder.gif"></img> &nbsp;<span> KINDERGARTEN</span> </Link></div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className={`form-section ${active}`}>
                <div className='form-block'> 
                    <div className='close' onClick={handleClose}>
                        <i class="bi bi-x-lg"></i>
                    </div>
                    <div className='form-heading'>
                        <h3> Registration Here</h3>
                        <h6><b>Registration Fee 300 Rs. Only</b></h6>
                    </div>
                    <form className='form-feilds' onSubmit={handleSubmit}>
                        <div>
                            <label>Student Name</label>
                            <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                            {errors.name && <div className="error">{errors.name}</div>}
                        </div>  
                        <div>
                            <label>Father's Name</label>
                            <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange}/>
                            {errors.fatherName && <div className="error">{errors.fatherName}</div>}
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone" className="form-control" maxlength={10} value={formData.phone} onChange={handleChange}/>
                            {errors.phone && <div className="error">{errors.phone}</div>}
                        </div> 
                        <div>
                        <label>Current School Name</label>
                        <input type="text"  name="school" className="form-control" value={formData.school} onChange={handleChange} />
                        {errors.school && <div className="error">{errors.school}</div>}
                    </div>
                    <div>
                        <label>Current School Phone Number</label>
                        <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone1" className="form-control" maxlength={10}  value={formData.phone1} onChange={handleChange} />
                        {errors.phone1 && <div className="error">{errors.phone1}</div>} 
                    </div> 
                <div>
                    <label>Upload Pay Slip (JPEG, PDF):</label>
                    <input className="form-control"
                        type="file"
                        accept=".jpeg, .jpg, .pdf"
                        onChange={handleChange}
                    />
                    {errors.attachments && <div className="error">{errors.attachments}</div>}
                </div>
                    <div className="selectclass">
                    <label>Class in which admission is Sought</label>
                    <select name="class" value={formData.class} onChange={handleChange}>
                        <option value="">Select a class</option> 
                        <option value="nursery">Nursery</option>
                        <option value="lkg">LKG</option>
                        <option value="ukg">UKG</option> 
                        <option value="1"> Class I</option>
                        <option value="2"> Class II</option>
                        <option value="3"> Class III</option>
                        <option value="4"> Class IV</option>
                        <option value="5"> Class V</option>
                        <option value="6"> Class VI</option>
                        <option value="7"> Class VII</option>
                        <option value="8"> Class VIII</option>
                        <option value="9"> Class IX</option>
                        <option value="10"> Class X</option>
                        <option value="11"> Class XI</option>
                        <option value="12"> Class XII</option>
                    </select>
                    {errors.class && <div className="error">{errors.class}</div>}
                </div>
                <div className="qrcode">
                      <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/qrcode.png"/>  
                </div>
                        <div className="submit">
                            <input type="submit" value="Submit" /> 
                        </div>
                        <div className="row"> 
                            <div className="col-lg-12 col-md-12">
                                <div className='primary-alert'></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

      {visible &&  <div className={`form-section visible`}>
      <div className='form-block'> 
          <div className='close' onClick={handlePopuClose}>
              <i class="bi bi-x-lg"></i>
          </div>
          <div className='form-heading'>
              <h3>  Admission Enquiry </h3>
              
          </div>
           <AdmissionEnquiry/>
      </div>
  </div> }
           
            <div className="menu">

                {isOpen && (
                    <div>
                        <div class="logo">
                            <NavLink to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Logo.png" /></NavLink>
                        </div>
                        <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">

                            <li>about
                                <ul className="sub-submenu">
                                    <li><NavLink to='/About'>About</NavLink></li>
                                    <li><NavLink to='/Vision'>Vision</NavLink></li>
                                    <li><NavLink to='/Mission'>Mission</NavLink></li>
                                    <li><NavLink to='/DirectorMessage'>Director's Desk</NavLink></li>
                                    <li><NavLink to='/PrincipalMessage'>Principal's Desk</NavLink></li>
                                    <li><NavLink to='/RulesRegulations'>Rules & Regulations</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                Facilities

                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Sports</NavLink></li>
                                    <li><NavLink to=''>Transport</NavLink></li>
                                    <li><NavLink to=''>Fully Air Conditioned Classroom</NavLink></li>
                                    <li><NavLink to=''>Free coaching for IIT’s</NavLink></li>
                                    <li><NavLink to=''>Cultural & Dance</NavLink></li>
                                    {/* <li><NavLink to='/Infrastructure'>Infrastructure</NavLink></li> */}

                                </ul>
                            </li>
                            <li>
                                Courses

                                <ul className="sub-submenu">
                                    <li><NavLink to='/'> Stream Offered For XI<sup>th</sup> Class</NavLink></li>
                                    <li><NavLink to=''> Non Medical</NavLink></li>
                                    <li><NavLink to=''>Medical</NavLink></li>
                                    <li><NavLink to=''>Commerce</NavLink></li>
                                    <li><NavLink to=''>Humanities</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                Gallery

                                <ul className="sub-submenu">
                                    <li><NavLink to='/VideoGallery'> Video Gallery</NavLink></li>
                                    <li><NavLink to='/Activites'>Activities</NavLink></li>
                                    <li><NavLink to='/Competition'>Competition</NavLink></li>
                                    <li><NavLink to='/Assemblies'>Assemblies </NavLink></li>
                                    <li><NavLink to='/Achivement'>Achievements </NavLink></li>
                                    <li><NavLink to='/Celebrations'>Celebrations </NavLink></li>
                                    <li><NavLink to='/Sports'>Sports</NavLink></li>

                                </ul>
                            </li> 
                            <li>
                            Careers
                              <ul className="sub-submenu">
                                <li><NavLink to='/Career'>Careers</NavLink></li>
                                </ul> 
                            </li>
                            <li>
                                Enquiry
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Admission</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                contact
                                <ul className="sub-submenu">
                                    <li><NavLink to='/contactUs'>Contact us</NavLink></li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;