import React, { useState} from 'react';
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
const AdmissionEnquiry = () => {
    const [successMessage, setSuccessMessage] = useState("");
    const [formData, setFormData] = useState({
        name: "", 
        phone: "",
        fatherName: "",
        school: "",
        phone1: "", 
        class: "",  
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => { 
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
            setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
        
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.fatherName) newErrors.fatherName = "Father's Name is required.";
        if (!formData.phone) newErrors.phone = "Phone Number is required.";
        if (!formData.school) newErrors.school = "School Name is required.";
        if (!formData.phone1) newErrors.phone1 = "Phone Number is required."; 
        if (!formData.class) newErrors.class = "Please select a class.";
       
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'XYZ'; 
        console.log('Submitting form with type:', type); // Debugging line

        
       
       
        try {
            const { header } = getHeader(); // Make sure this function returns the correct headers
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            setSuccessMessage("Your Form Submitted Successfully.");
            setFormData({
                name: "", 
                phone: "",
                fatherName: "",
                school: "",
                phone1: "", 
                class: "",
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  return (
     <>
     <form className='form-feilds' onSubmit={handleSubmit}>
                <div>
                    <label>Student Name</label>
                    <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                    {errors.name && <div className="error">{errors.name}</div>}
                </div>  
                <div>
                    <label>Father's Name</label>
                    <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange}/>
                    {errors.fatherName && <div className="error">{errors.fatherName}</div>}
                </div>
                <div>
                    <label>Phone Number</label>
                    <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone" className="form-control" maxlength={10} value={formData.phone} onChange={handleChange}/>
                    {errors.phone && <div className="error">{errors.phone}</div>}
                </div> 
                <div>
                <label>Current School Name</label>
                <input type="text"  name="school" className="form-control" value={formData.school} onChange={handleChange} />
                {errors.school && <div className="error">{errors.school}</div>}
            </div>
            <div>
                <label>Current School Phone Number</label>
                <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone1" className="form-control" maxlength={10}  value={formData.phone1} onChange={handleChange} />
                {errors.phone1 && <div className="error">{errors.phone1}</div>}
            </div>  
            <div>
            <label>Class in which admission is Sought</label>
            <select name="class" value={formData.class} onChange={handleChange}>
                <option value="">Select a class</option> 
                <option value="nursery">Nursery</option>
                <option value="lkg">LKG</option>
                <option value="ukg">UKG</option> 
                <option value="1"> Class I</option>
                <option value="2"> Class II</option>
                <option value="3"> Class III</option>
                <option value="4"> Class IV</option>
                <option value="5"> Class V</option>
                <option value="6"> Class VI</option>
                <option value="7"> Class VII</option>
                <option value="8"> Class VIII</option>
                <option value="9"> Class IX</option>
                <option value="10"> Class X</option>
                <option value="11"> Class XI</option>
                <option value="12"> Class XII</option>
            </select>
            {errors.class && <div className="error">{errors.class}</div>}
            </div> 
                <div className="submit">
                    <input type="submit" value="Submit" /> 
                </div>
                <div className="row primarydiv"> 
                    <div className="col-lg-12 col-md-12">
                    {successMessage && <div className='primary-alert'>{successMessage}</div>} 
                    </div>
                </div>
            </form>
     </>
  )
}

export default AdmissionEnquiry
