import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
function RulesRegulations() {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Rules & Regulations" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Rules &  </span> Regulations</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/pdf/Rules-Regulations.pdf" width="100%" height="900px"></iframe>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default RulesRegulations