import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const SMC = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="CBSE Disclosure" breadcrumbName="School Management Committee" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>School Management </span> Committee</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                           <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th> Sl. No. </th>
                                        <th>NAME </th> 
                                        <th>ADDRESS </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.</td>
                                        <td>Shri Sudesh Gupta Chairman</td>
                                        <td>H. No. 1492, Sec-14 Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Shri C. L. Pardesi Member</td>
                                        <td>H. No. 1484, Sec-14 Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Shri. Deviya Gupta Member</td>
                                        <td>H. No. 91 Sec-16A Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Shri. B. B. Gupta Member</td>
                                        <td>H. No. 1181, Sec-19, Fourth Floor, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>Dr. M.C.Gupta Member</td>
                                        <td>House No. 562, Sec-15 Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Shri. Rohitash Tayal Member</td>
                                        <td>H. No. 255, Sec-16A Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>Mr. Onkar Singh Shekhawat Member Secretary</td>
                                        <td>H. No. D-1141 Sector- 49, Sainik Colony, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>Mr. Sanjeev Abrol Member</td>
                                        <td>J-103, SECTOR-10 DLF, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>9.</td>
                                        <td>Mrs. Manju Vashishtha Member</td>
                                        <td>H. No. 597, Sec-31 Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>10.</td>
                                        <td>Mr. Narinder Parmar Member Appointed</td>
                                        <td>H. No. 198, Sec-15 Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>11.</td>
                                        <td>Mrs. Kalpana Gupta Member Nominated</td>
                                        <td>H. No. 1181, Sec-19, Fourth Floor, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>12.</td>
                                        <td>Mr. Alok Gupta Member (Parent)</td>
                                        <td>H.No. 5/105, Sihi Gate, Ballabgarh, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>13.</td>
                                        <td>Mrs. Priyanka Kaushik Member (Parent)</td>
                                        <td>H. NO. 91, VILL JUNHERA Thesil Ballabgarh Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>14.</td>
                                        <td>Mr. S.S. Gosain Member</td>
                                        <td>Divine Public School Sect-9, Faridabad</td>
                                    </tr>
                                    <tr>
                                        <td>15.</td>
                                        <td>Mrs. Reeta Jaggi Member</td>
                                        <td>K.L Mehta sec-7, Faridabad</td>
                                    </tr>
                                </tbody>
                            </table>
                           </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default SMC